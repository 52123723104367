<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index" :style="{
                backgroundImage: 'url(' + item.cover + ')',
                backgroundSize: '100% 100%',
                backgroundPosition: '0% 0%'
            }">
                <div class="title">{{ item.title }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.body {
    display: block;
    width: 100%;
    max-width: 1200px;
    color: #fff;
    margin: 88px auto 0 auto;
}

.body ul {
    margin-left: -3%;
}

.body li {
    width: 47%;
    height: 580px;
    padding: 360px 0 0 4.3%;
    border-radius: 8px;
    margin: 0 0 40px 3%;
    float: left;
}

.title {
    font-size: 34px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .body {
        margin-top: 40px;
    }
    .body ul {
        margin: 0 auto;
    }

    .body li {
        height: 400px;
        padding: 250px 0 0 8%;
        float: none;
        width: 90%;
        margin: 0 auto 40px auto;
    }

    .title {
        font-size: 24px;
    }
}
</style>
